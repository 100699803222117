import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import {
  FC,
  FormEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Mutation } from 'react-apollo';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import {
  cjConfig,
  convertTimeZoneForDB,
  convertTimeZoneForFE,
  createTealiumCall,
  getPremiumInfo,
} from '../../../common/utility';
import AccountMenu from '../../../components/AccountMenu';
import CustomAlert from '../../../components/CustomAlert';
import Dropdown from '../../../components/Dropdown';
import { TextField } from '../../../components/fields';
import FooterNew from '../../../components/layout/FooterNew';
import { AppContext } from '../../../contexts';
import { UPDATE_PROFILE_PICTURE_MUTATION } from '../account/__generated__/gql';
import { useStyles } from './styles';

import {
  UpdateProfilePicture,
  UpdateProfilePictureVariables,
} from './__generated__/gql/__generated__/UpdateProfilePicture';

import {
  UpdateProfileStudentAccountMutation,
  UpdateProfileStudentAccountMutationVariables,
} from './__generated__/UpdateProfileStudentAccountMutation';

import { diamondComponent } from '../../../common/components';
import {
  COUNTRY_NAMES,
  PackageShortNames,
  US_STATES_NAMES,
  tealiumConstants,
} from '../../../common/constants';
import { badWordsRegx } from '../../../common/utility';
import SelectTimeZone from '../../../components/SelectTimeZone';
import ChangePasswordPage from './ChangePasswordPage';
import BannerMessage from '../home/BannerMessage';

const stateCodes = Object.keys(US_STATES_NAMES);
stateCodes.sort();
const statesList = stateCodes.map((state) => {
  return { label: state, value: state };
});

const countriesList = COUNTRY_NAMES.map((country: string) => {
  COUNTRY_NAMES.sort();
  return { label: country, value: country };
});

export const UPDATE_STUDENT_ACCOUNT_MUTATION = gql`
  mutation UpdateProfileStudentAccountMutation(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $pictureUrl: String
    $highSchool: String
    $highSchoolYear: String
    # $applyingYear: String
    $gpa: String
    $sat: String
    $gapYear: Boolean
    $act: String
    $timezone: String
    $undergraduateGradYear: String
    $undergraduateCollege: String
    $userLocation: JSON
  ) {
    updateStudent(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        pictureUrl: $pictureUrl
        gapYear: $gapYear
        phoneNumber: $phoneNumber
        highSchool: $highSchool
        highSchoolYear: $highSchoolYear
        # applyingYear: $applyingYear
        gpa: $gpa
        sat: $sat
        act: $act
        timezone: $timezone
        userLocation: $userLocation
        undergraduateGradYear: $undergraduateGradYear
        undergraduateCollege: $undergraduateCollege
      }
    ) {
      firstName
      lastName
      email
      pictureUrl
      phoneNumber
      highSchool
      gpa
      sat
      act
      highSchoolYear
      # applyingYear
      undergraduateGradYear
      timezone
    }
  }
`;
interface zone_array {
  label: string;
  value: string;
}
const checkCountryIsUS = (country: string) => {
  return country.includes('United States of America');
};

export const regexNoSpace =
  /^[a-zA-Z-\u4E00-\u9FCC-!@#$&'()\\-`.+,/\"]+( [a-zA-Z-\u4E00-\u9FCC-!@#$&'()\\-`.+,/\"]+)*$/;

const ProfilePage: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const { user, setUser } = useContext(AppContext);
  const pictureUrl = user && user.pictureUrl;
  const [state, setState] = useState({
    checkedA: true,
    pictureUrl,
  });

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [editPicture, setEditPicture] = useState(false);
  const [file, setFile] = useState<any>();
  const [isCountryUS, setIsCountryUS] = useState(
    checkCountryIsUS(user?.userLocation?.country || '')
  );

  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();
  const timeZone = user && user.timezone;
  let timeZoneValue: zone_array;
  if (timeZone !== null) {
    timeZoneValue = {
      label: timeZone.toString(),
      value: timeZone.toString(),
    };
  }

  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  let highSchoolValue: any = user && parseInt(user.highSchoolYear || '');
  highSchoolValue =
    highSchoolValue > currentYear + 6 || highSchoolValue < currentYear
      ? 'Other'
      : highSchoolValue;

  const [yearValue, setYearValue] = useState(highSchoolValue);
  useEffect(() => {
    createTealiumCall({
      site_vertical: tealiumConstants.SITE,
      site_portal: tealiumConstants.PROFILE,
      site_product: tealiumConstants.PROFILE_PRODUCT,
      site_product_section: tealiumConstants.PROFILE_PRODUCT_SECTION,
      site_product_subsection: tealiumConstants.PROFILE_PRODUCT_SUBSECTION,
      page_type: tealiumConstants.PROFILE_PAGE_TYPE,
      content_type: tealiumConstants.PROFILE_CONTENT_TYPE,
    });
    cjConfig({ userId: user?.id });
  }, []);

  function toggleAlert(variant?: string, message?: string) {
    if (variant && message) window.scrollTo(0, 0);
    setVariant(variant || '');
    setMessage(message || '');
  }

  if (!user) return null;
  const {
    email,
    firstName,
    isParent,
    lastName,
    phoneNumber,
    highSchool,
    gpa,
    sat,
    act,
    highSchoolYear,
    timezone,
    gapYear,
    userLocation,
  } = user;
  const initialValues = {
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    pictureUrl: state.pictureUrl,
    phoneNumber: phoneNumber || '',
    highSchool: highSchool || '',
    gpa: gpa || '',
    sat: sat || '',
    act: act || '',
    highSchoolYear: highSchoolYear || '',
    timezone: timezone || '',
    gapYear: gapYear,
    city: userLocation?.city || '',
    state: userLocation?.state || '',
    zipCode: userLocation?.zipCode || '',
    country: userLocation?.country || '',
  };

  const FormSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .matches(badWordsRegx, 'Name contains bad word.')
      .required('Required')
      .nullable(),
    lastName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .matches(badWordsRegx, 'Name contains bad word.')
      .required('Required')
      .nullable(),
    gpa: Yup.number().typeError('It should be a number'),
    sat: Yup.number().typeError('It should be a number'),
    act: Yup.number().typeError('It should be a number'),
    zipCode: Yup.number().typeError('It should be a number'),
    city: Yup.string()
      .matches(
        regexNoSpace,
        'City may only contain letters, commas, apostrophes, and dashes.'
      )
      .matches(badWordsRegx, 'Name contains bad word.')
      .nullable(),
    timezone: Yup.string(),
    phoneNumber: Yup.string().required('Required'),
    highSchoolYear: !isParent
      ? Yup.number()
          .min(
            currentYear - 2,
            `Please enter value greater than ${currentYear - 3}`
          )
          .max(
            currentYear + 10,
            `Please enter value less than ${currentYear + 11}`
          )
          .required('Required')
          .nullable()
      : Yup.number()
          .min(
            currentYear - 2,
            `Please enter value greater than ${currentYear - 3}`
          )
          .max(
            currentYear + 10,
            `Please enter value less than ${currentYear + 11}`
          )
          .nullable(),
    highSchool: Yup.string().matches(
      regexNoSpace,
      'High school name should contain only alphabets without any Leading or trailing spaces'
    ),
  });

  const { essaysLeft, videosLeft, premiumPackageType } = getPremiumInfo(user);

  let triedSubmit = false;
  const temporaryArray = [1, 2, 3, 4, 5, 6, 7];
  let YEARS_ARRAY: any = [];
  temporaryArray.forEach((val) => {
    YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
  });
  YEARS_ARRAY.push({ label: currentYear, value: currentYear });
  YEARS_ARRAY.push({ label: 'Other', value: 'Other' });
  const renderFormField = (
    label: string,
    name: string,
    setFieldValue: Function,
    disabled: Boolean,
    onFieldChange?: Function
  ) => {
    return (
      <>
        <Typography
          className={classes.texfieldLabels}
          variant="body2"
          color="inherit"
        >
          {label}
        </Typography>
        <Field
          type="text"
          name={name}
          component={TextField}
          InputLabelProps={{ shrink: true }}
          fullWidth
          label={false}
          variant="outlined"
          disabled={disabled}
          className={disabled ? classes.disabledFieldBackgroundColor : ''}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            if (onFieldChange) onFieldChange(e, setFieldValue);
            else {
              const {
                currentTarget: { value },
              } = e;
              setFieldValue(name, value);
            }
          }}
        />
      </>
    );
  };
  const onChangeGradutationYear = (
    e: FormEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const {
      currentTarget: { value },
    } = e;

    const numberArray: RegExpMatchArray | null = value.match(/\d+/g);
    if (numberArray) {
      setFieldValue('highSchoolYear', numberArray.toString());
    } else {
      setFieldValue('highSchoolYear', '');
    }
  };
  return (
    <Fragment>
      <Container maxWidth={false}>
        <Box mb="50px">
          <Box className={classes.myAccountHeader}>
            <Typography variant="h3">My Account</Typography>
          </Box>

          <CustomAlert
            variant={variant}
            message={message}
            toggleAlert={toggleAlert}
          />

          { user?.isPremium && user?.isRisk ? <BannerMessage /> : null }

          <Grid container spacing={2}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <AccountMenu isRisk={user?.isPremium && user?.isRisk || false} />
            </Grid>

            <Grid item lg={9} md={8} sm={12} xs={12}>
              <Card className={classes.cardTitle}>
                <Mutation<
                UpdateProfileStudentAccountMutation,
                  UpdateProfileStudentAccountMutationVariables
                >
                  mutation={UPDATE_STUDENT_ACCOUNT_MUTATION}
                >
                  {(updateStudentAccountMutation: Function) => {
                    return (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={FormSchema}
                        onSubmit={async (variables, { setSubmitting }) => {
                          try {
                            const { country, city, state, zipCode, ...other } =
                              variables;
                            const result = await updateStudentAccountMutation({
                              variables: {
                                ...other,
                                userLocation: {
                                  country: country || null,
                                  city: city || null,
                                  state: state || null,
                                  zipCode: zipCode || null,
                                },
                                timezone: convertTimeZoneForDB(
                                  (variables?.timezone || '') as string
                                ),
                              },
                            });
                            const {
                              data: { updateStudent },
                            } = result;

                            if (user && window.analytics) {
                              const {
                                firstName,
                                lastName,
                                phoneNumber,
                                highSchool,
                                gpa,
                                sat,
                                act,
                                email,
                                timezone,
                              } = updateStudent;
                              if (window.analytics) {
                                window.analytics.identify(user.id, {
                                  'First Name': firstName || '',
                                  'Last Name': lastName || '',
                                  'Phone Number': phoneNumber || '',
                                  'Full Name': `${firstName || ''} ${
                                    lastName || ''
                                  }`,
                                  'High School': highSchool || '',
                                  GPA: gpa || '',
                                  SAT: sat || '',
                                  ACT: act || '',
                                  Email: email || '',
                                  'Package Type': premiumPackageType,
                                  'Number of Essay Credits Left': essaysLeft,
                                  'Number of Video Chat Sessions Left':
                                    videosLeft,
                                  name: `${firstName || ''} ${lastName || ''}`,
                                  email: email || '',
                                  timezone:
                                    convertTimeZoneForFE(timezone) || '',
                                });
                              }
                            }
                            const updatedValues = {
                              email: updateStudent.email || '',
                              firstName: updateStudent.firstName || '',
                              lastName: updateStudent.lastName || '',
                              phoneNumber: updateStudent.phoneNumber || '',
                              highSchool: updateStudent.highSchool || '',
                              gpa: updateStudent.gpa || '',
                              sat: updateStudent.sat || '',
                              act: updateStudent.act || '',
                              highSchoolYear:
                                updateStudent.highSchoolYear || '',

                              timezone:
                                convertTimeZoneForFE(updateStudent.timezone) ||
                                '',
                            };

                            setUser({
                              ...user,
                              ...updatedValues,
                              userLocation: {
                                __typename: 'studentLocation',
                                city: city || '',
                                country: country || '',
                                state: state || '',
                                zipCode: zipCode || null,
                              },
                            });
                            toggleAlert('success', 'Changes Saved');
                            setSubmitting(false);
                          } catch (error) {
                            console.log('error: ', error);
                            setSubmitting(false);
                            toggleAlert(
                              'danger',
                              'Something went wrong, Please try again'
                            );
                          }
                        }}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          handleSubmit,
                          setSubmitting,
                          values,
                          errors,
                        }) => {
                          if (isSubmitting) triedSubmit = isSubmitting;

                          return (
                            <>
                              <Form>
                                <Grid container>
                                  <Box width="100%">
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      className={classes.accountInformation}
                                    >
                                      <Typography variant="h4">
                                        Account Information
                                      </Typography>

                                      <Grid className={classes.plansButton}>
                                        {!user.isParent && !isTrial ? (
                                          user.premiumPackageType ? (
                                            <Box
                                              textAlign="right"
                                              color={user?.premiumPackageColor}
                                            >
                                              <Typography
                                                color="inherit"
                                                variant="h5"
                                                component="h5"
                                                className={classes.packageTitle}
                                              >
                                                {premiumPackageType ===
                                                PackageShortNames.DIAMOND
                                                  ? diamondComponent()
                                                  : premiumPackageType}
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <NavLink to="/account/billing">
                                              <Button
                                                variant="contained"
                                                className={classes.upGrade}
                                              >
                                                UPGRADE
                                              </Button>
                                            </NavLink>
                                          )
                                        ) : null}
                                      </Grid>
                                    </Box>

                                    <Divider className='DividerColor'/>

                                    <Box paddingX="25px">
                                      <Box
                                        className={
                                          file
                                            ? classes.actionButtonsFile
                                            : classes.actionButtons
                                        }
                                      >
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            lg={8}
                                            md={7}
                                            sm={12}
                                            xs={12}
                                          >
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Avatar
                                                src={state.pictureUrl || ''}
                                                className={classes.profileLogo}
                                              />

                                              <Box maxWidth="100%">
                                                <Typography
                                                  variant="h5"
                                                  className="userProfileName"
                                                >{`${firstName} ${lastName}`}</Typography>
                                                {!editPicture && !file && (
                                                  <Grid className="editProfilePicture">
                                                    <label>
                                                      Edit Profile Picture
                                                      <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={({
                                                          currentTarget: {
                                                            files,
                                                          },
                                                        }) => {
                                                          try {
                                                            if (
                                                              files &&
                                                              files.length
                                                            ) {
                                                              setEditPicture(
                                                                true
                                                              );
                                                              setFile(files[0]);
                                                              setState({
                                                                checkedA: true,
                                                                pictureUrl:
                                                                  URL.createObjectURL(
                                                                    files[0]
                                                                  ),
                                                              });
                                                            }
                                                          } catch (error) {
                                                            console.log(
                                                              'error: ',
                                                              error
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </label>
                                                  </Grid>
                                                )}
                                              </Box>
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={4}
                                            md={5}
                                            sm={12}
                                            xs={12}
                                            className={
                                              classes.profileSaveDiscard
                                            }
                                          >
                                            {file && (
                                              <Mutation<
                                                UpdateProfilePicture,
                                                UpdateProfilePictureVariables
                                              >
                                                mutation={
                                                  UPDATE_PROFILE_PICTURE_MUTATION
                                                }
                                              >
                                                {(
                                                  uploadFileMutation: Function
                                                ) => (
                                                  <Button
                                                    variant="contained"
                                                    className="savePicture profileButton"
                                                    disabled={!editPicture}
                                                    onClick={async () => {
                                                      try {
                                                        setEditPicture(false);
                                                        const result =
                                                          await uploadFileMutation(
                                                            {
                                                              variables: {
                                                                file,
                                                                UserId: user.id,
                                                              },
                                                            }
                                                          );
                                                        const {
                                                          data: {
                                                            uploadProfilePicture:
                                                              {
                                                                source,
                                                                success,
                                                              },
                                                          },
                                                        } = result;
                                                        setUser({
                                                          ...user,
                                                          pictureUrl: source,
                                                        });
                                                        setFile('');
                                                        if (!success)
                                                          return alert(
                                                            'file not uplaoded'
                                                          );
                                                      } catch (error) {
                                                        console.log(
                                                          'error: ',
                                                          error
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Save Picture
                                                  </Button>
                                                )}
                                              </Mutation>
                                            )}
                                            {file && (
                                              <Button
                                                variant="outlined"
                                                className="discardButton profileButton"
                                                disabled={!editPicture}
                                                onClick={() => {
                                                  setFile('');
                                                  setEditPicture(false);
                                                  setState({
                                                    checkedA: true,
                                                    pictureUrl:
                                                      (user &&
                                                        user.pictureUrl) ||
                                                      '',
                                                  });
                                                }}
                                              >
                                                Discard
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box padding="0px 25px" width="100%">
                                    <Grid container spacing={2}>
                                      <Grid item lg={4} md={4} sm={12} xs={12}>
                                        {renderFormField(
                                          'First Name',
                                          'firstName',
                                          setFieldValue,
                                          false
                                        )}
                                        {errors.firstName && (
                                          <div className="requiredText">
                                            {errors.firstName}
                                          </div>
                                        )}
                                      </Grid>

                                      <Grid item lg={4} md={4} sm={12} xs={12}>
                                        {renderFormField(
                                          'Last Name',
                                          'lastName',
                                          setFieldValue,
                                          false
                                        )}
                                        {errors.lastName && (
                                          <div className="requiredText">
                                            {errors.lastName}
                                          </div>
                                        )}
                                      </Grid>

                                      {!isParent && (
                                        <Grid
                                          item
                                          lg={4}
                                          md={4}
                                          sm={12}
                                          xs={12}
                                          className={classes.labelStyle}
                                        >
                                          <Dropdown
                                            label={`Graduation Year`}
                                            errorValue={`High School Graduation Year`}
                                            selectedValue={yearValue || ''}
                                            options={YEARS_ARRAY}
                                            isError={triedSubmit && !yearValue}
                                            name="year"
                                            onSelect={(evt: any) => {
                                              const {
                                                target: { value },
                                              } = evt;
                                              setYearValue(value);
                                              if (value !== 'Other')
                                                setFieldValue(
                                                  'highSchoolYear',
                                                  value.toString()
                                                );
                                            }}
                                          />
                                        </Grid>
                                      )}

                                      {typeof yearValue === 'string' &&
                                        yearValue === 'Other' && (
                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            sm={12}
                                            xs={12}
                                          >
                                            {renderFormField(
                                              'Graduation Year',
                                              'highSchoolYear',
                                              setFieldValue,
                                              false,
                                              onChangeGradutationYear
                                            )}
                                          </Grid>
                                        )}

                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        {renderFormField(
                                          'Email',
                                          'email',
                                          setFieldValue,
                                          true
                                        )}
                                      </Grid>

                                      <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <Typography
                                          variant="body2"
                                          color="inherit"
                                          className={classes.texfieldLabels}
                                        >
                                          Phone Number
                                        </Typography>

                                        <Box className="phoneInputMasterResolve">
                                          <Field
                                            name="phoneNumber"
                                            placeholder={false}
                                            component={TextField}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            validate={(value: any | string) => {
                                              if (
                                                value &&
                                                !isValidPhoneNumber(value)
                                              ) {
                                                return 'Invalid Phone Number';
                                              }
                                            }}
                                          >
                                            {({
                                              field,
                                              form: {
                                                errors,
                                                handleBlur,
                                                touched,
                                                setFieldTouched,
                                                ...rest
                                              },
                                              ...otherProps
                                            }: any) => {
                                              return (
                                                <>
                                                  <PhoneInput
                                                    value={field.value}
                                                    defaultCountry="US"
                                                    {...rest}
                                                    {...otherProps}
                                                    onBlur={() => {
                                                      setFieldTouched(
                                                        field.name,
                                                        true
                                                      );
                                                    }}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        field.name,
                                                        value
                                                      );
                                                    }}
                                                    error={
                                                      touched.phoneNumber &&
                                                      errors.phoneNumber
                                                    }
                                                  />

                                                  {errors.phoneNumber && (
                                                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                                                      {errors.phoneNumber}
                                                    </p>
                                                  )}
                                                </>
                                              );
                                            }}
                                          </Field>
                                        </Box>
                                      </Grid>
                                      <Grid className={classes.gapYearCheckbox}>
                                        {!isParent && (
                                          <FormGroup row>
                                            <FormControlLabel
                                              color="primary"
                                              control={
                                                <Checkbox
                                                  checked={
                                                    values.gapYear &&
                                                    values.gapYear === true
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() =>
                                                    setFieldValue(
                                                      'gapYear',
                                                      !values.gapYear
                                                    )
                                                  }
                                                />
                                              }
                                              label="I am taking a gap year."
                                            />
                                          </FormGroup>
                                        )}
                                      </Grid>

                                      <Grid
                                        item
                                        md={4}
                                        sm={12}
                                        xs={12}
                                        className={classes.labelStyle}
                                      >
                                        <Dropdown
                                          label={'Country'}
                                          errorValue={``}
                                          emptyDisplay
                                          selectedValue={values.country}
                                          options={countriesList}
                                          name="country"
                                          onSelect={(evt: any) => {
                                            setFieldValue(
                                              'country',
                                              evt.target.value
                                            );
                                            if (
                                              checkCountryIsUS(evt.target.value)
                                            ) {
                                              setIsCountryUS(true);
                                              values.state = '';
                                              values.zipCode = '';
                                            } else {
                                              setIsCountryUS(false);
                                              setFieldValue('state', '');
                                              setFieldValue('zipCode', '');
                                            }
                                          }}
                                        />
                                      </Grid>

                                      <Grid item md={4} sm={12} xs={12}>
                                        {renderFormField(
                                          'Hometown (City)',
                                          'city',
                                          setFieldValue,
                                          false
                                        )}
                                        {errors.city && (
                                          <div className="requiredText">
                                            {errors.city}
                                          </div>
                                        )}
                                      </Grid>

                                      {isCountryUS && (
                                        <>
                                          <Grid
                                            item
                                            md={2}
                                            sm={12}
                                            xs={12}
                                            className={classes.labelStyle}
                                          >
                                            <Dropdown
                                              label="State"
                                              name="state"
                                              errorValue={``}
                                              selectedValue={values.state}
                                              options={statesList}
                                              onSelect={(evt: any) => {
                                                setFieldValue(
                                                  'state',
                                                  evt.target.value
                                                );
                                              }}
                                            />
                                          </Grid>

                                          <Grid item md={2} sm={12} xs={12}>
                                            <Box component="span">
                                              {renderFormField(
                                                'ZIP Code',
                                                'zipCode',
                                                setFieldValue,
                                                false
                                              )}
                                              {errors.zipCode && (
                                                <div className="requiredText">
                                                  {errors.zipCode}
                                                </div>
                                              )}
                                            </Box>
                                          </Grid>
                                        </>
                                      )}

                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <SelectTimeZone
                                          isMultiSelect={false}
                                          label="Time Zone"
                                          value={
                                            timeZoneValue || 'Please Select'
                                          }
                                          getValue={(timeZone: zone_array) => {
                                            setFieldValue(
                                              'timezone',
                                              timeZone.value
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>

                                <Box padding="20px 25px 36px">
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                    color="primary"
                                  >
                                    Save Changes
                                  </Button>
                                </Box>
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    );
                  }}
                </Mutation>
              </Card>

              <ChangePasswordPage />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <FooterNew />
    </Fragment>
  );
};

export default ProfilePage;
