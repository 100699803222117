import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Colors from '../common/colors';
import fonts from '../common/fonts';

export const loaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(1),
      color: Colors.BLUE_SEVEN,
      width: '86px !important',
      height: '86px !important',
    },
    smallProgress: {
      margin: theme.spacing(1),
      color: Colors.BLUE_SEVEN,
      width: '25px !important',
      height: '25px !important',
    },

    loaderContainer: {
      background: Colors.WHITE_ONE,
      opacity: 0.9,
      backgroundFilter: 'blur(5px)',

      '& .MuiCircularProgress-circleIndeterminate': {
        strokeWidth: 3,
      },
    },
    tLoaderContainer: {
      opacity: 0.9,
      backgroundFilter: 'blur(5px)',

      '& .MuiCircularProgress-circleIndeterminate': {
        strokeWidth: 3,
      },
    },

    loaderText: {
      textTransform: 'uppercase',
    },
  })
);

export const editUserInfoDialog = makeStyles(() =>
  createStyles({
    dialog: {
      '& div[role="dialog"]': {
        padding: '30px',
        maxWidth: '490px',
        minWidth: '300px',
        boxSizing: 'content-box',
        width: '40vw',
        borderRadius: '20px',
      },
      '& .inputError': {
        input: {
          borderColor: 'red !important',
          outline: 'red !important',
        },
      },
    },
    heading: {
      fontWeight: 600,
      fontFamily: fonts.POPPINS_FONT,
    },
    dialogActions: {
      paddingRight: '0px !important',
      borderTop: '0px !important',
    },
  })
);

export const accountMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    dotStudentProfile: {
      height: 8,
      width: 8,
      backgroundColor: '#2F80ED',
      borderRadius: '50%',
      display: 'inline-block',
      marginBottom: 1,
    },
    accountNav: {
      padding: 0,
      borderRadius: '15px',
      boxSizing: 'border-box',
      border: '1px solid #D4D4D4',
      background: Colors.WHITE_ONE,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
    },

    navLinkIcon: {
      marginRight: '18px',
    },

    navLink: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
      height: '54px',
      letterSpacing: '0.3375px',
      color: Colors.BLACK_FIVE,
      fontWeight: 400,
      fontSize: 16,
      fontFamily: fonts.POPPINS_FONT,

      '&:first-child': {
        borderRadius: '15px 15px 0px 0px',
      },

      '&:last-child': {
        borderRadius: '0px 0px 15px 15px',
      },

      '&.active': {
        background: Colors.GRAY_BOREDER,
        fontWeight: 600,

        [theme.breakpoints.down('md')]: {
          fontWeight: 'bold',
        },
      },
    },
  })
);
