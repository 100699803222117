import React from 'react';

import {
  Box,
  ClickAwayListener,
  Hidden,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip';
import ExecutiveMentorshipTooltipContent from './ExecutiveMentorshipTooltipContent';
import InfoIcon from '../../../img/rating-info-icon.svg';
import { useStylesBootstrap } from './styles';

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function ExecutiveMentorshipTooltip() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <BootstrapTooltip
          placement="top-start"
          title={<ExecutiveMentorshipTooltipContent />}
        >
          <Box ml="12px">
            <IconButton className="tooltipHoverButton">
              <img src={InfoIcon} alt="info" />
            </IconButton>
          </Box>
        </BootstrapTooltip>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <BootstrapTooltip
            placement="top-start"
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            onClose={handleTooltipClose}
            title={<ExecutiveMentorshipTooltipContent />}
          >
            <Box ml="12px">
              <IconButton
                className="tooltipHoverButton"
                onClick={handleTooltipOpen}
              >
                <img src={InfoIcon} alt="info" />
              </IconButton>
            </Box>
          </BootstrapTooltip>
        </ClickAwayListener>
      </Hidden>
    </>
  );
}
