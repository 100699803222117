import { Typography } from '@material-ui/core';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

export default function MilestoneTooltipContent() {
  return (
    <>
      <Typography
        variant="body1"
        style={{
          font: fonts.INTER,
          fontWeight: 400,
          fontSize: 16,
          color: colors.BLACK_THIRTEEN,
        }}
      >
        Includes (10) hours of 1-1 Academic or <br /> Standardized Test
        (SAT/ACT) Tutoring from <br /> Revolution Prep.
      </Typography>
    </>
  );
}
