import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '../../common/colors';
import fonts from '../../common/fonts';

export const useStylesHeader = makeStyles((theme: Theme) =>
  createStyles({
    dotStudentProfile: {
      height: 14,
      width: 14,
      backgroundColor: '#2F80ED',
      borderRadius: '50%',
      marginBottom: 25,
      marginLeft: '-12px',
    },
    dotProfileDropDown: {
      height: 8,
      width: 8,
      backgroundColor: '#2F80ED',
      borderRadius: '50%',
      marginLeft: 10,
    },
    profileBadge: {
      marginLeft: 12,
      display: 'flex',
      cursor: 'pointer',
      borderRadius: '50%',

      '&.profile-picture': {
        width: 50,
        // width: 37,
        height: 37,
        // backgroundColor: '#d4d4d4',

        '& img': {
          borderRadius: '50%',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPostion: 'top',
        },

        '& svg': {
          width: 37,
          height: 37,
          marginLeft: 15,
        },
      },
    },

    profileBadgeContainer: {
      '&:hover': {
        '& #profileBadgeMenu': {
          display: 'block',
          marginTop: 15,
        },
      },
    },

    chatDisplay: {
      '@media only screen and (max-width: 1024px)': {
        display: 'none',
      },
      '& a': {
        '& span': {
          '& span': {
            height: '14px !important',
            minWidth: '14px !important',
            fontSize: 10,
          }
        }
      },
    },

    profileBadgeMenu: {
      display: 'none',
      textAlign: 'right',
      cursor: 'auto',
      right: 0,
      paddingTop: 0,

      '& a': {
        maxWidth: 'fit-content',
      },
    },

    linkMenuItem: {
      paddingTop: 16,

      '& a': {
        cursor: 'pointer',
        color: colors.BLACK_TWELVE,
        fontSize: 18,
        fontFamily: fonts.POPPINS_FONT,

        '&:hover': {
          color: colors.BLACK_TWELVE,
          textDecoration: 'none',
          opacity: 0.7,
        },
      },
    },

    userName: {
      '& h3': {
        fontWeight: 600,
        fontSize: 22,
        lineHeight: '20px',
        color: colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,
        textAlign: 'left',
        marginBottom: 0,
      },
    },

    popoverSignOut: {
      fontSize: 16,
      textTransform: 'uppercase',
      color: `${colors.GREEN_EIGHT} !important`,
      fontFamily: fonts.POPPINS_FONT,
      cursor: 'pointer',
      fontWeight: 600,

      '&:hover': {
        textDecoration: 'none',
      },
    },

    changeAccount: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      textTransform: 'uppercase',
      cursor: 'pointer',
      fontFamily: fonts.POPPINS_FONT,
      textAlign: 'left',
    },

    profilePopover: {
      background: colors.WHITE_ONE,
      boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.25)`,
      padding: '30px 24px',
      borderRadius: 15,
      width: 351,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    profileCard: {
      display: 'flex',
      alignItems: 'center',
    },

    upGrade: {
      color: colors.WHITE_ONE,
      background: colors.YELLOW_THREE,
      width: 154,
      height: 46,
      padding: '5px 6px !important',
      fontSize: '18px !important',
      fontWeight: 600,
      borderRadius: 25,

      '& span': {
        fontSize: '18px !important',
        textTransform: 'uppercase',
      },

      '&:hover': {
        backgroundColor: colors.YELLOW_FOUR,
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 14,
        fontSize: '14.1538px !important',
      },

      '&.with-profilePic': {
        height: 46,
        minWidth: 186,
        borderRadius: 25,
        fontWeight: 600,
        padding: '5px 6px !important',
        border: '1px solid #d4d4d4',
        backgroundColor: 'transparent',
        paddingLeft: '18px !important',
        justifyContent: 'space-between',

        '& svg, & img': {
          marginRight: 0,
        },

        '& > span': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px !important',
          textTransform: 'uppercase',

          '& > span': {
            flex: 1,
            textAlign: 'center',
          },
        },
      },
      '&.with-diamond': {
        minWidth: 205,
      },
    },

    navItemsFree: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        padding: '10px 0px',
      },
    },

    navItems: {
      marginLeft: 60,

      [theme.breakpoints.down('md')]: {
        marginLeft: 30,
      },

      '& > a': {
        '&:not(:last-child)': {
          marginRight: 47,

          [theme.breakpoints.down('md')]: {
            marginRight: 12,
            fontSize: 15,
          },
        },
      },

      '@media only screen and (max-width: 959px)': {
        display: 'none',
      },
    },

    navLink: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: '-0.011em',
      color: '#272929',

      '& .MuiBadge-root': {
        display: 'flex',
      },
    },

    profileAvtar: {
      display: 'flex',
      alignItems: 'center',

      '@media only screen and (max-width: 959px)': {
        display: 'none',
      },
    },

    hamburgerMenu: {
      '@media only screen and (min-width: 960px)': {
        display: 'none',
      },
    },

    hamburgerMenuLogin: {
      '& svg': {
        color: colors.WHITE_ONE,
      },
    },

    dropDownContainer: {
      top: 11,
      paddingTop: 20,
      position: 'absolute',
      left: -15,
      width: 273,
      display: 'none',
      zIndex: 9999,

      '& ul': {
        padding: '18px 25px',
        listStyle: 'none',
        left: 0,
        background: colors.WHITE_ONE,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: 15,
        margin: 0,

        '& li': {
          marginBottom: 8,
        },

        '& a': {
          margin: 0,
        },
      },
    },

    dropDownLink: {
      position: 'relative',

      '& a': {
        cursor: 'pointer',
      },

      '&:hover .dropdown-container': {
        display: 'block',
      },
    },

    greenDot: {
      height: 10,
      width: 10,
      backgroundColor: colors.GREEN_EIGHT,
      borderRadius: '50%',
      position: 'absolute',
      top: -1,
      right: -3,
    },

    mentorsList: {
      '& a': {
        display: 'flex',
        alignItems: 'center',
        color: colors.BLACK_TEN,

        '&:not(:last-child)': {
          marginBottom: 10,
        },

        '& .advisor-image': {
          height: 36,
          width: 36,
          borderRadius: '50%',
          overflow: 'hidden',
        },
      },

      '& img': {
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        objectFit: 'cover',
      },
    },

    mentorsListContainer: {
      '&:not(:last-child)': {
        marginBottom: 10,
      },
    },

    premiumPopupContainer: {
      position: 'relative',

      '&:hover': {
        '& .MuiBox-root': {
          display: 'block',
        },
      },

      '& hr': {
        borderTop: `1px solid ${colors.GRAY_BOREDER}`,
      },
    },

    premiumHeading: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 16,
      textTransform: 'capitalize',
      paddingBottom: 10,
      margin: 0,
      textAlign: 'left',
    },

    preMeetingCard: {
      background: '#EBF3FE !important',
      borderRadius: 0,
      padding: 20,
      marginBottom: 5,
      border: 0,
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        padding: '20px 16px',
      },

      '& .anyQuestionContact': {
        marginTop: '15px !important',
      },

      '& .meetingTitle': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,

        '& img': {
          marginRight: 5,
        },
      },

      '& .meetingSubTitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        margin: '7px 0px 18px',
      },

      '& .meetingDate': {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#2F80ED',
        marginBottom: 18,
        fontFamily: fonts.INTER,
      },
    },

    LinkButtonText: {
      textTransform: 'capitalize',
    },

    linkFormCopied: {
      background: '#2668C1 !important',
    },

    joinMeeting: {
      minWidth: '182px !important',
      height: 40,
    },

    linkForm: {
      minWidth: 208,
      height: 40,
      background: '#2F80ED',
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      fontFamily: fonts.POPPINS_FONT,
      boxShadow: 'none',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#2668C1',
      },

      '& img': {
        marginRight: 10,
      },
    },

    graySection: {
      background: '#F8F6F4',
      margin: '0 -24px 0',
      textAlign: 'left',
      padding: '27px 24px 22px',
      color: colors.BLACK_TEN,
      fontFamily: fonts.POPPINS_FONT,
      fontSize: 16,

      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 20px',
        padding: '27px 16px 22px',
      },
    },

    upgradeThemeSection: {
      backgroundColor: '#FFF8E4',

      '& .anchorCursor': {
        cursor: 'initial',
      },

      '& .upgradeSubtitle': {
        fontSize: 14,
        fontWeight: 400,
        color: '#525454',
        letterSpacing: '-0.006em',
        paddingBottom: 18,
      },

      '& .upgradeSubtitleRestore': {
        padding: 0,
        maxWidth: 250,
      },

      '& .phoneNumberUpgrade': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        paddingTop: 17,
        textAlign: 'center',
      },
    },

    upGradeHeading: {
      fontSize: 18,
      fontWeight: 600,
      color: '#272929',
      letterSpacing: '-0.014em',
      paddingBottom: 7,
      fontFamily: fonts.POPPINS_FONT,

      '& img': {
        marginRight: 10,
      },
    },

    callButton: {
      width: '100%',
      color: colors.WHITE_ONE,
      backgroundColor: colors.YELLOW_THREE,

      '&:hover': {
        backgroundColor: colors.YELLOW_FOUR,
      },
    },

    meetingsDisable: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },

      '& a': {
        color: '#A9A9A9',

        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },

        '&:hover': {
          opacity: 1,
          color: '#A9A9A9',
          cursor: 'not-allowed',
        },
      },

      '& img': {
        marginRight: 12,
      },
    },

    navLinkSignout: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '0.006em',
      textTransform: 'uppercase',
      color: '#2F80ED',
      fontFamily: fonts.POPPINS_FONT,
    },

    mobileDrawer: {
      width: 292,
      padding: '20px 26px 40px',

      [theme.breakpoints.down('sm')]: {
        padding: '16px !important',
      },

      '& .navListItem': {
        paddingLeft: 0,
        paddingRight: 0,
      },

      '& .drawerUserName': {
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .listGutter': {
        paddingLeft: 0,
        paddingRight: 0,
      },

      '& .essayGuideDivider': {
        background: '#D4D4D4',
        margin: '14px 0px 22px',
      },
    },

    premiumPackages: {
      height: 46,
      minWidth: 186,
      fontWeight: 600,
      borderRadius: 25,
      boxShadow: 'none',
      textAlign: 'center',
      fontSize: '18px !important',
      border: '1px solid #d4d4d4',
      padding: '5px 6px !important',
      backgroundColor: 'transparent',
    },

    signoutListItem: {
      padding: '32px 0px 0px',
    },

    navLinkDivider: {
      background: '#D4D4D4',
      margin: '0px 0px 16px',
    },

    parentStudentLink: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      textDecorationLine: 'none',
      color: '#2F80ED',
      borderBottom: '1px solid #2F80ED',
      fontFamily: fonts.INTER,
      display: 'flex',
      marginLeft: 'auto',
      cursor: 'pointer',
      textTransform: 'initial',

      [`@media only screen and (max-width: 414px)`]: {
        fontSize: 11,
      },
    },

    switchParentLink: {
      width: 109,
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      textDecorationLine: 'none',
      color: '#2F80ED',
      borderBottom: '1px solid #2F80ED',
      fontFamily: fonts.INTER,
      display: 'flex',
      marginBottom: 26,
    },

    userNameSwitch: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: '-0.011em',
      color: '#272929',
      marginBottom: 4,
      textAlign: 'right',
    },

    surveyUserName: {
      fontWeight: 600,
      fontSize: '18px',
      letterSpacing: '-0.014em',
      lineHeight: '24px',
      color: '#525454',
      marginBottom: '4px',
      textAlign: 'right',
    },

    bookFree: {
      fontWeight: 600,
      fontSize: 16,
      textAlign: 'center',
      color: '#272929',
      fontFamily: fonts.INTER,
    },

    headerAppBar: {
      padding: 0,
    },

    appBarSchedule: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    appBarGeneric: {
      padding: 0,
      minHeight: '97px',
    },

    scheduleUserName: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: '-0.014em',
      color: '#7D7F7F',
      fontFamily: fonts.INTER,
    },

    userNameSwitchFlex: {
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },

      '& .logoutLink': {
        [`@media only screen and (max-width: 414px)`]: {
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },

      '& .logoutLinkSpacing': {
        marginRight: 12,

        [`@media only screen and (max-width: 414px)`]: {
          margin: '0px 0px 5px 0px',
        },
      },
    },

    editLink: {
      color: '#2F80ED',
      textDecoration: 'underline',
      cursor:'pointer'
    },
  })
);
