import {
  Box,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { FC, useContext } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../../../contexts';

import { useStyles } from './styles';

import moment from 'moment-timezone';
import { formatter } from '../../../common/utility';
import DownloadIcon from '../../../img/download.svg';

export const GET_USER_INVOICES_FROM_STRIPE = gql`
  query GetUserInvoicesFromStripeQuery {
    getUserInvoicesFromStripe {
      success
      invoices {
        has_more
        count
        url
        data {
          id
          created
          currency
          invoice_pdf
          status
          total
          cardBrand
          cardLast4
          hosted_invoice_url
        }
      }
    }
  }
`;

const currentdate = new Date();
const currentYear = currentdate.getFullYear();
const temporaryArray = [1, 2, 3, 4, 5, 6];
const YEARS_ARRAY: any = [];
temporaryArray.map((val) => {
  YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
});
YEARS_ARRAY.push({ label: currentYear, value: currentYear });
YEARS_ARRAY.push({ label: 'Other', value: 'Other' });

const OldBilling: FC = () => {
  const classes = useStyles();

  const { user } = useContext(AppContext);
  const {
    tableMainContainer,
    tableHead,
    tableCell,
    billingTable,
    statusStyle,
  } = useStyles();

  return (
    <Query<any> query={GET_USER_INVOICES_FROM_STRIPE}>
      {({ data }) => {
        const invoices = data?.getUserInvoicesFromStripe?.invoices?.data
          ?.filter((_invoice: any) => _invoice)
          ?.sort((a: any, b: any) => a.created - b.created);
        return (
          <Grid container>
            <Grid container lg={12} md={12} xs={12} alignItems="center">
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="h4">Payment History</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}></Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider className={classes.DividerColor} />
              </Grid>
            </Grid>
            <Box className={`${tableMainContainer} ${billingTable}`}>
              <Table className={classes.billingTableWrapper}>
                <TableHead>
                  <TableRow>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">Payment Method</Typography>
                    </TableCell>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">Date</Typography>
                    </TableCell>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">Plan</Typography>
                    </TableCell>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">Amount</Typography>
                    </TableCell>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">Status</Typography>
                    </TableCell>
                    <TableCell className={tableHead}>
                      <Typography variant="h6">RECEIPT</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices &&
                    invoices.map((billDetails: any, index: number) => {
                      const {
                        total,
                        created,
                        invoice_pdf,
                        status,
                        hosted_invoice_url,
                        cardLast4,
                        cardBrand,
                      } = billDetails;
                      return (
                        <TableRow
                          key={index}
                          className={
                            invoice_pdf
                              ? classes.billingTableRow
                              : `${classes.billingTableRow} unavailable`
                          }
                        >
                          <TableCell className={tableCell}>
                            <Typography variant="body2">
                              {cardBrand
                                ? cardBrand +
                                  (cardLast4 ? ' ending in ' + cardLast4 : '')
                                : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <Typography variant="body2">
                              {created
                                ? moment(created * 1000).format('MMM Do, YYYY')
                                : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <Typography variant="body2">
                              {user?.premiumPackageType || '--'}
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <Typography variant="body2">
                              {formatter.format(total / 100)}
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <Typography variant="body2" className={statusStyle}>
                              {status}
                            </Typography>
                          </TableCell>
                          <TableCell className={tableCell}>
                            <Grid className="deleteIcon">
                              {invoice_pdf ? (
                                <IconButton aria-label="delete" size="small">
                                  <a href={invoice_pdf}>
                                    <img src={DownloadIcon} alt="download" />
                                  </a>
                                </IconButton>
                              ) : (
                                <Typography variant="body2">--</Typography>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell className={tableCell}>
                            {status.toLowerCase() === 'past due' &&
                            hosted_invoice_url ? (
                              <Typography
                                variant="body2"
                                className={statusStyle}
                              >
                                <a target="_blank" href={hosted_invoice_url}>
                                  Pay Now
                                </a>
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                className={statusStyle}
                              >
                                --
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        );
      }}
    </Query>
  );
};

export default withRouter(OldBilling);
