import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';
import PICKER_ARROW_ICON from '../../../img/arrow-icon-picker.svg';
import PICKER_ICON from '../../../img/picker-date-icon.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addedToPackage: {
      color: '#2F80ED',
      borderRadius: 12,
      background: '#ffffff',
      border: '2px solid #2F80ED',

      '&:hover, &:focus': {
        border: '2px solid #2F80ED',
        background: '#ffffff !important',
      },
    },

    polygenceDialog: {
      "& [class*='MuiTypography-h6']": {
        fontSize: 36,
        fontWeight: 700,
        color: '#272929',
        lineHeight: '42px',
        letterSpacing: '-0.456px',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiDialog-paper']": {
        padding: 58,
        maxWidth: 720,
      },

      "& [class*='MuiTypography-body2']": {
        fontSize: 16,
        fontWeight: 400,
        color: '#525454',
        lineHeight: '24px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },

      "& [class*='MuiTypography-caption']": {
        fontSize: 16,
        fontWeight: 400,
        color: '#A9A9A9',
        marginLeft: 25,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },

      "& [class*='MuiIconButton-root']": {
        padding: 3,

        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },

    polygenceAddOn: {
      '& .learnMorePolygence': {
        marginTop: 58,
        cursor: 'pointer',
        fontSize: '18px !important',
        color: colors.GRAY_TWENTYTWO,
        lineHeight: '18px !important',
        display: 'inline-block !important',
        letterSpacing: '-0.252px !important',

        [theme.breakpoints.down('sm')]: {
          marginTop: 30,
          fontSize: '14px !important',
        },
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 12,
        fontWeight: 400,
        color: '#A9A9A9',
        lineHeight: '12px',
        fontFamily: fonts.INTER,
        display: 'inline-block',
        letterSpacing: '-0.072px',
        borderBottom: `1px solid ${colors.GRAY_TWENTYTWO}`,
      },

      "& [class*='MuiTypography-h5']": {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        lineHeight: '12px',
        marginBottom: 34,
        letterSpacing: '-0.456px',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    promoCodeSpacing: {
      marginBottom: 20,
    },

    divider: {
      margin: '0px 0px 25px',
    },

    smartMatch: {
      marginBottom: 25,

      '& .changeSelection': {
        cursor: 'pointer',
        lineHeight: '18px',
        fontFamily: fonts.INTER,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.084px',
        borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
      },

      '& .smartWatchText': {
        fontSize: 14,
        marginRight: 14,
        color: '#A9A9A9',
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },
    },

    ceTableContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    tablePrices: {
      marginTop: 28,

      '& tr': {
        backgroundColor: 'transparent !important',

        '& th': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 700,
          fontSize: 20,
          letterSpacing: '-0.019em',
          textAlign: 'center',
          borderRight: '2px solid #D4D4D4',
          textTransform: 'uppercase',
          lineHeight: '24px',
          padding: '0px 16px 17px',

          '&:last-child': {
            borderRight: 0,
          },
        },

        '& td': {
          height: 68,
          fontSize: 24,
          color: '#525454',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.019em',
          textAlign: 'center',
          padding: '0px 15px',
          backgroundColor: 'transparent',
          borderTop: '2px solid #d4d4d4',
          borderRight: '2px solid #D4D4D4',

          '& strong': {
            fontWeight: 800,
          },

          '&:last-child': {
            borderRight: 0,
          },

          '&:first-child': {
            fontFamily: fonts.INTER,
            fontWeight: 600,
            fontSize: 14,
            letterSpacing: '-0.014em',
            color: '#7D7F7F',
            // backgroundColor: '#f4f4f4',
            textAlign: 'left',
            paddingRight: 32,
          },
          '& .milestone': {
            justifyContent: 'flex-start',
          },
        },
      },
    },
    tHeadDescription: {
      fontSize: '12px',
      fontWeight: 600,
      color: colors.BLACK_FOURTEEN,
      textTransform: 'capitalize',
      '&.mobile': {
        fontSize: '10px',
        marginTop: '5px',
      },
    },
    sectionStyles: {
      color: `${colors.BLACK_THIRTEEN} !important`,
      // fontWeight: 'bold !important',
      '&.sectionStyles': {
        backgroundColor: `${colors.GRAY_BOREDER} !important`,
      },
    },
    articleStyles: {
      fontWeight: 600,
      backgroundColor: `rgba(244,244,244,.25) !important`,
    },
    textCell: {
      fontFamily: `${fonts.INTER} !important`,
      fontSize: '14px !important',
      lineHeight: '20px !important',
      textAlign: 'center',
      letterSpacing: '-0.006em !important',
      color: `${colors.BLACK_FOURTEEN} !important`,
    },
    sectionRow: {
      borderTopWidth: '3px !important',
      borderTopColor: `${colors.BLACK_FOURTEEN} !important`,
    },
    boldHr: {
      borderWidth: '3px !important',
    },
    diamondLine: {
      borderColor: '#A5ADFF !important',
    },
    pinnacleLine: {
      borderColor: `${colors.WHITE_ONE} !important`,
    },

    // ClientEnrollmentTableMobile
    tablePricesMobile: {
      marginTop: 16,
      width: '100%',
      borderSpacing: 0,
      borderCollapse: 'separate',

      '& tr': {
        backgroundColor: 'transparent !important',

        '&:last-child': {
          '& td': {
            '&:first-child': {
              borderBottomLeftRadius: 5,
            },

            '&:last-child': {
              borderBottomRightRadius: 5,
            },
          },
        },

        '& th': {
          fontFamily: fonts.POPPINS_FONT,
          padding: '18px 7px',
          fontSize: 14,
          fontWeight: 600,
          textAlign: 'center',
          textTransform: 'uppercase',
          border: '1px solid #d4d4d4',
          borderRight: 0,

          '&:first-child': {
            borderTopLeftRadius: 5,
          },

          '&:last-child': {
            borderTopRightRadius: 5,
            borderRight: '1px solid #d4d4d4',
          },
        },

        '& td': {
          fontWeight: 600,
          fontSize: 12,
          color: '#525454',
          fontFamily: fonts.POPPINS_FONT,
          textAlign: 'center',
          padding: 7,
          backgroundColor: 'transparent',
          border: '1px solid #d4d4d4',
          borderTop: 0,
          borderRight: 0,

          '&:last-child': {
            borderRight: '1px solid #d4d4d4',
          },
        },

        '& .planHeadingText': {
          textAlign: 'left',
          fontWeight: 400,
          fontSize: 12,
          color: '#7D7F7F',
          padding: '2px 11px',
          backgroundColor: '#F4F4F4',
        },
        '& .planHeadingText.boldHeading': {
          fontWeight: 600,
        },
        '&.shaddyRow': {
          backgroundColor: `rgba(244,244,244,.25) !important`,
        },
        '& .milestone': {
          justifyContent: 'flex-start',
        },

        '& .planNameText': {
          lineHeight: '14px',
        },

        '& .planPriceText': {
          fontWeight: 400,
          fontSize: 12,
          textAlign: 'center',
          color: '#525454',
          margin: '8px 0px',
        },
      },
    },

    planPriceMobile: {
      '& .planAccordion': {
        width: '100%',
      },

      '& .planAccordionSummary': {
        width: '100%',

        '& div:first-child': {
          width: '100%',
        },
      },
    },

    promoCode: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30,
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'center',
      },

      '& .promoInput': {
        maxWidth: 126,
        marginRight: theme.spacing(1),

        '& input': {
          fontSize: 14,
          letterSpacing: '-0.006em',

          '&::placeholder': {
            color: '#A9A9A9',
          },
        },
      },

      '& a': {
        textDecoration: 'underline',
        color: colors.GRAY_TWENTYONE,
        cursor: 'pointer',
        fontWeight: 500,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& p': {
        marginRight: 5,
        textTransform: 'capitalize',
      },
    },

    promoDetails: {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },

    //   PackageDetails
    packageDetailsContainer: {
      padding: '48px 0',
    },

    purchaseHeader: {
      paddingBottom: 55,

      [theme.breakpoints.down('sm')]: {
        paddingBottom: 20,
      },
    },

    summaryHeader: {
      padding: '21px 24px',
      borderBottom: `1px solid ${colors.WHITE_SEVEN}`,
    },

    promoCodeSection: {
      '& .dividerPromo': {
        margin: '24px 0px 20px',
      },

      '& .promoCodeText': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',

        '& a': {
          marginLeft: 19,
        },
      },
    },

    packageItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    innerGrid: {
      padding: '27px 24px 26px',
      backgroundColor: colors.GRAY_BOREDER,
      borderBottom: `1px solid ${colors.WHITE_SEVEN}`,

      '& .orderSummaryPayment': {
        fontSize: 16,
        fontWeight: 700,
        color: '#525454',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        '& span': {
          fontWeight: 400,
          textTransform: 'lowercase',
        },
      },

      '& .seniorResourceProgram': {
        padding: '5px 0px 3px',

        '& .captionText': {
          fontSize: 12,
          fontWeight: 500,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.072px',
        },
      },
    },

    lineThrough: {
      fontSize: 16,
      fontWeight: 400,
      marginRight: 15,
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.176px',
      textDecoration: 'line-through',
    },

    removePromo: {
      fontSize: 12,
      fontWeight: 400,
      color: '#A9A9A9',
      cursor: 'pointer',
      letterSpacing: '-0.072px',
      fontFamily: fonts.INTER,
      borderBottom: '1px solid #A9A9A9',

      '&:hover': {
        color: '#A9A9A9 !important',
        borderBottom: '1px solid #A9A9A9 !important',
      },
    },

    summaryBody: {
      padding: '25px 25px 30px',

      '& .dueTodayAmount': {
        fontSize: 24,
        fontWeight: 700,
        color: '#2F80ED',
        letterSpacing: '-0.456px',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .dueText': {
        fontSize: 24,
        fontWeight: 700,
        letterSpacing: '-0.456px',
        color: '#2F80ED',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .paymentFailedText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 12,
        color: '#E94444',
        lineHeight: '16px',
      },

      '& .paymentFailed': {
        marginTop: 24,
        display: 'flex',
        alignItems: 'flex-start',

        '& img': {
          marginRight: 12,
        },
      },

      '& .moneyBack': {
        marginTop: 20,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,

        '& img': {
          marginRight: 11,
        },
      },
    },

    mainCardContainer: {
      padding: '28px 24px',
      marginBottom: 17,
      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },
    },

    checkboxElement: {
      paddingTop: '0px !important',
      width: '40px !important',
    },
    packageTitle: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: '-0.456px',
      fontFamily: fonts.POPPINS_FONT,
      display: 'flex',

      '& img': {
        marginBottom: '4px',
      },
    },

    addParentButton: {
      color: colors.BLUE_SEVEN,
    },
    changeStudentButton: {
      fontWeight: 600,
      cursor: 'pointer',
      display: 'inline',
      textTransform: 'uppercase',
      fontFamily: fonts.POPPINS_FONT,
      fontSize: '12px',
    },
    disableText: {
      cursor: 'not-allowed',
    },
    simpleText: {
      fontSize: '12px',
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 400,
    },

    contractFullName: {
      marginBottom: 27,
    },

    contractContainerCheckbox: {
      marginLeft: -9,
    },

    contractContainer: {
      '& .fullName': {
        maxWidth: 260,
      },

      "& [class*='MuiSvgIcon-root']": {
        width: 26,
        height: 26,

        '& path': {
          color: '#A9A9A9',
        },
      },

      '& .electronicFields': {
        marginRight: '20px',
        width: '100%',

        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#F4F4F4',
        },
      },
      '& .inputBaseCaptionDisabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      '& .inputBaseCaption': {
        fontSize: 12,
        fontWeight: 400,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',

        [theme.breakpoints.down('xs')]: {
          marginTop: 15,
        },
      },

      '& .electronicSubTitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .widthInputBase': {
        width: '100%',
        border: `1px solid ${colors.WHITE_SEVEN}`,
        padding: '7px 12px',
        color: '#272929',
        fontFamily: fonts.INTER,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',

        '& input': {
          padding: 0,
        },
      },

      '& .datePickerField': {
        maxWidth: 170,
      },
      '& .datePicker': {
        margin: 0,
        background: '#FFFFFF',
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        padding: '0px 12px',
        height: 32.63,
        maxWidth: 183,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 17px',
        backgroundImage: `url(${PICKER_ARROW_ICON})`,

        '& .Mui-disabled': {
          opacity: 0.9,
          cursor: 'not-allowed',
          color: '#A9A9A9 !important',
        },

        '&.datePickerN': {
          '& .Mui-disabled': {
            color: '#272929 !important',
          },
        },

        '& ::after, & ::before': {
          display: 'none',
        },

        '& input': {
          border: 0,
          padding: 0,
          height: 32,
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.084px',
          color: '#A9A9A9',
          fontFamily: fonts.INTER,
        },

        '& button': {
          padding: 0,
          width: 20,
          height: 32,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${PICKER_ICON})`,

          '& svg': {
            display: 'none',
          },
        },
      },
      '& .datePickerDisabled': {
        opacity: 0.9,
        color: '#A9A9A9 !important',
        cursor: 'not-allowed !important',
        backgroundColor: '#F4F4F4 !important',
      },
    },

    // TILACard

    container: {
      width: '100%',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      borderRadius: '15px',
      marginBottom: '10px',
      background: 'white',
    },
    heading: {
      fontFamily: fonts.POPPINS_FONT,
      backgroundColor: '#F4F4F4',
      color: '#7D7F7F',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '15px 15px 0 0',
      padding: '27px 23px 23px',
      [theme.breakpoints.down('xs')]: {
        padding: '20px 16px',
      },
    },
    packageName: {
      fontFamily: fonts.POPPINS_FONT,
      fontSize: '24px',
      fontWeight: 700,
      color: 'inherit',
      textTransform: 'uppercase',
    },
    subTitle: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#7D7F7F',
      '&.description': {
        color: '#A9A9A9',
      },
    },
    tableRow: {
      backgroundColor: 'transparent !important',
      '&>*:nth-child(1)': {
        borderWidth: '1px 0px 0px 1px',
        border: 'solid #EAEAEA',
      },
      '&>*:nth-child(2)': {
        borderWidth: '1px 1px 0px 1px',
        border: 'solid #EAEAEA',
      },
      '&:last-child': {
        borderBottom: '1px solid #EAEAEA',
      },
      '& *': {
        textAlign: 'left',
      },
    },
    tableContainer: {
      width: `calc(100% - 48px) !important`,
      margin: '24px',
    },
    toggleIcon: {
      padding: '0px !important',
      minWidth: 'unset',
      '&:hover': {
        background: 'unset !important',
      },
    },
    collapseTitle: {
      color: '#A9A9A9',
      fontWeight: 600,
      fontFamily: fonts.POPPINS_FONT,
    },
    disabledButton: {
      backgroundColor: '#45CE93 !important',
      color: 'white !important',
    },

    disclosure: {
      padding: '26px 24px 42px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 16px',
      },

      '& .packageName': {
        '& h6': {
          fontWeight: 700,
          fontSize: 24,
          letterSpacing: '-0.019em',
          fontFamily: fonts.POPPINS_FONT,
        },
      },

      '& .packageFullName': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .packageAmount': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .mobileFlexColumn': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },

      '& .mobileFlexResponsive': {
        textAlign: 'right',

        [theme.breakpoints.down('xs')]: {
          margin: '20px 0px',
          textAlign: 'left',
        },
      },
    },
    diamondTableContainer: {
      maxWidth: '250px',
      minWidth: '235px',
      boxSizing: 'border-box',
      borderRadius: '25px',
      marginLeft: '20px',
      backgroundColor: colors.BLUE_SHADE_ONE,
      '& h5': {
        fontSize: '20px',
        color: colors.BLUE_FOURTEEN,
      },
    },
    diamondTable: {
      '& tbody': {
        '& tr': {
          '&:first-child': {
            '& td:first-child': {
              height: '70px !important',
              boxSizing: 'border-box',
              fontFamily: fonts.POPPINS_FONT,
              fontWeight: 700,
              fontSize: 20,
            },
          },
          '&:nth-child(6)': {
            '& td': {
              height: '136px !important',
              padding: '11px 22px 0px 22px',
            },
          },
          '&:nth-child(9)': {
            '& td': {
              fontWeight: 800,
              fontSize: 24,
            }
          },
          '&:nth-child(10)': {
            '& td': {
              fontWeight: 800,
              fontSize: 24,
            }
          },
        },
      },
      '& tr': {
        background: 'none !important',
      },
      '& td': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        fontSize: '14px',
        textAlign: 'center',
        fontFamily: fonts.INTER,
        color: colors.BLUE_FOURTEEN,
        boxSizing: 'border-box',
        paddingBottom: '0px',
        '& strong': {
          fontWeight: 800,
        },
        
        '& hr': {
          margin: 'unset',
          width: '160px',
          borderTop: `1px solid #A5ADFF`,
        },
        height: '68px !important',
      },
    },
    pinnacleTableContainer: {
      maxWidth: '272px',
      minWidth: '264px',
      boxSizing: 'border-box',
      borderRadius: '25px',
      marginLeft: '20px',
      background: `linear-gradient(149.14deg, #8D98FF -44.34%, #475B8A 63.2%, #6776FF 186.93%)`,
      '& h5': {
        fontSize: '20px',
        color: colors.WHITE_ONE,
      },
    },
    pinnacleTable: {
      '& tbody': {
        '& tr': {
          '&:first-child': {
            '& td:first-child': {
              height: '70px !important',
              boxSizing: 'border-box',
              fontFamily: fonts.POPPINS_FONT,
              fontWeight: 700,
              fontSize: 20,
            },
          },
          '&:nth-child(7)': {
            '& td': {
              height: '136px !important',
              padding: '11px 22px 0px 22px',
            },
          },
          '&:nth-child(14)': {
            '& td': {
              fontWeight: 800,
              fontSize: 24,
            }
          },
          '&:nth-child(15)': {
            '& td': {
              fontWeight: 800,
              fontSize: 24,
            }
          },
        },
      },
      '& tr': {
        background: 'none !important',
      },
      '& td': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        fontSize: '14px',
        textAlign: 'center',
        fontFamily: fonts.INTER,
        color: colors.WHITE_ONE,
        boxSizing: 'border-box',
        paddingBottom: '0px',

        '& strong': {
          fontWeight: 800,
        },

        '& hr': {
          margin: 'unset',
          width: '160px',
        },
        '& .info': {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '20px',
          textAlign: 'center',
          letterSpacing: '-0.006em',

          color: colors.WHITE_ONE,
        },
        height: '68px !important',
      },
    },
  })
);

export const CEStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      maxWidth: 1130,
      margin: theme.spacing(0, 'auto'),

      '& .Mui-expanded': {
        borderBottom: 0,
      },
    },

    enrollmentContainer: {
      paddingBottom: 50,
    },

    pageHeadingPadding: {
      paddingBottom: '0px !important',
    },

    pageHeading: {
      padding: '56px 0 70px',

      [theme.breakpoints.down('xs')]: {
        padding: '40px 0px 25px',
      },

      '& h3': {
        fontSize: 36,
        fontWeight: 700,
        color: '#272929',
        paddingBottom: 11,
        lineHeight: '42px',
        letterSpacing: '-0.022em',

        [theme.breakpoints.down('xs')]: {
          maxWidth: 265,
          fontSize: 28,
          lineHeight: '36px',
          textAlign: 'center',
          letterSpacing: 'initial',
        },
      },
    },

    linkContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 37,
      },

      '& a': {
        textDecoration: 'underline',
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#2F80ED',
      },
    },

    enrollmentFeature: {
      backgroundColor: colors.WHITE_ONE,
    },

    typoButton: {
      backgroundColor: 'transparent !important',
      outline: 'none !important',
      border: 'none !important',
      color: colors.BLUE_SEVEN,
      textDecoration: 'underline',
      fontSize: '14px',
      '&:hover': {
        textDecoration: 'none',
      },
    },

    plansPriceDivider: {
      marginTop: 62,

      '& .divider': {
        width: '100%',
        background: '#D4D4D4',
      },
    },

    faqAskedQuestion: {
      paddingTop: 30,
      borderTop: '1px solid #d4d4d4',

      '&:before': {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px !important',
        paddingTop: 11,
        justifyContent: 'flex-start',
        borderTop: '1px solid #d4d4d4',
      },
    },
    packageOptionHeading: {
      fontSize: '28px',
      linHeight: '',
    },
    headingContainer: {
      '& button': {
        background: 'none',
        border: 'none',
        '&:hover': {
          background: 'none',
        },
      },

      '& button.diamondButton': {
        height: 49,
        opacity: 0.5,
      },
    },
    clickableLetter: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

// MilestoneTeamTooltip

export const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    width: 22,
    height: 12,
    color: colors.WHITE_ONE,
    marginBottom: '-12px !important',
  },

  tooltip: {
    maxWidth: 746,
    borderRadius: 6,
    padding: '32px 33px',
    backgroundColor: colors.WHITE_ONE,
    border: `1px solid ${colors.GRAY_TWENTYTHREE}`,
    boxShadow: `0px 0px 1px ${colors.TOOLTIP_SHADOW_ONE}, 0px 40px 64px ${colors.TOOLTIP_SHADOW_TWO}`,

    '& .tooltipHeading': {
      '& b': {
        fontWeight: 700,
      },
    },

    '& .tooltipHeading, & .listText, & a': {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: fonts.INTER,
      letterSpacing: '-0.011em',
      color: colors.BLACK_TWELVE,
    },

    '& ul': {
      margin: 0,
      paddingTop: 27,

      '& li': {
        marginBottom: 9,
      },
    },
  },
}));
