import { Typography } from '@material-ui/core';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

export default function ExecutiveMentorshipTooltipContent() {
  return (
    <>
      <Typography
        variant="body1"
        style={{
          font: fonts.INTER,
          fontWeight: 400,
          fontSize: 16,
          color: colors.BLACK_THIRTEEN,
        }}
      >
        Develop and reinforce critical skills to stay organized,
        <br /> manage stress, and build confidence. This includes <br /> up to
        (10) hours of tailored support in:
        <br />
        <ul style={{marginLeft: '18px'}}>
          <li>Stress Management</li>
          <li>Organizational Skills</li>
          <li>Time Management</li>
          <li>Leadership & Communication</li>
          <li>Wellness & Balance</li>
        </ul>
      </Typography>
    </>
  );
}
