import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../contexts';

import { ClientStatus } from '../common/constants';

import Images from '../img';

import CAUTION_ICON from '../img/caution-icon-url.svg';
import FAILURE_ICON from '../img/failure-payment.svg';
import MyProfileIcon from '../img/my-profile-icon.svg';
import ColllegePreferences from '../img/college-preferences.svg';
import ParentAccountIcon from '../img/parent-account.svg';
import PlanBillingIcon from '../img/plan-billing.svg';
import UserProfileIcon from '../img/user-profile.svg';
import { accountMenuStyles } from './styles';
import client from '../apollo';
import { GET_PROFILE_DATA } from '../../src/pages/main/account/graphql/queries/myProfile';

const PROFILE_NAV: {
  title: string;
  linkTo: string;
  icon: string;
  optionalIcon?: string | null;
  indicatorIcon?: boolean | false;
}[] = [
  {
    title: 'Account Information',
    linkTo: '/account/profile',
    icon: UserProfileIcon,
  },
  {
    title: 'My Profile',
    linkTo: '/account/my-profile',
    icon: MyProfileIcon,
    indicatorIcon: true
  },
  {
    title: 'College Preferences',
    linkTo: '/account/college-preferences',
    icon: ColllegePreferences,
  },
];

interface PropsType {
  isRisk?: boolean | false;
}

const AccountMenu: React.FC<PropsType> = ({isRisk}) => {
  const { accountNav, navLink, navLinkIcon, dotStudentProfile } = accountMenuStyles();
  const { user } = useContext(AppContext);
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;
  const [inCompleteProfile, setInCompleteProfile] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>(null);
  const getStudentProfileData = async () => {
    try {
      const { data } = await client.query({
        query: GET_PROFILE_DATA,
        variables: {
          userId: user?.id
        },
        fetchPolicy: "network-only"
      })
      const { getProfileData } = data;
      setProfileData(getProfileData);
      let showProfileInComplete = false;
      const currentGradeYear = process.env.REACT_APP_CURRENT_GRAD_YEAR || '2025';
      const { highSchoolYear } = getProfileData;

      if (parseInt(highSchoolYear) === parseInt(currentGradeYear)) {
        // 'senior';
        showProfileInComplete = !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || (!getProfileData?.act && !getProfileData?.sat) || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length || !getProfileData?.areasOfInterest?.length;
      } else if (parseInt(highSchoolYear) >= (parseInt(currentGradeYear)+4)) {
        // 'middleSchool';
        showProfileInComplete = !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear;
      } else if ((parseInt(highSchoolYear) === (parseInt(currentGradeYear)+2)) || (parseInt(highSchoolYear) === (parseInt(currentGradeYear)+3))) {
        // somophore freshman
        showProfileInComplete = !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length || !getProfileData?.areasOfInterest?.length;
      } else {
        // 'junior';
        showProfileInComplete = !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length || !getProfileData?.areasOfInterest?.length;
      }
      setInCompleteProfile(showProfileInComplete);
    } catch (error) {
      console.log('error in AccountMenu.tsx getStudentProfileData', error);
    }
  }

  useEffect(() => {
    if(user?.isPremium) {
      getStudentProfileData();
    }
  });

  if (!user) return null;
  if (!(user?.isPremium && !user?.userSubscription)) {
    const find = PROFILE_NAV.find((nav) => {
      if (nav.title === 'Plan & Billing') {
        return nav;
      }
    });
    if (!find && !isTrial) {
      PROFILE_NAV.push({
        title: 'Plan & Billing',
        linkTo: '/account/billing',
        icon: PlanBillingIcon,
        optionalIcon:
          (user.planCancelled &&
            (user.clientStatus === ClientStatus.BAD_DEBT
              ? FAILURE_ICON
              : CAUTION_ICON)) ||
          null,
      });
    }
  }

  const { isParent } = user;

  return (
    <Box borderRadius={4} padding="20px 0" className={accountNav}>
      {PROFILE_NAV.map((item, index) => (
        <NavLink to={item.linkTo} key={item.title} className={navLink}>
          <img
            src={item.icon}
            alt={String(index)}
            className={navLinkIcon}
          ></img>
          {item.title}
          {item.optionalIcon && (
            <Box paddingLeft="20px">
              <img src={item.optionalIcon} width="15px" alt="caution" />
            </Box>
          )}
          {inCompleteProfile && item.indicatorIcon && (
            <Box paddingLeft="10px">
              <span className={dotStudentProfile}></span>
            </Box>
          )}
          {item.title.toLowerCase() === 'plan & billing' &&
            user &&
            (user?.lastPaymentStatus === 'failure' || isRisk) && (
              <img src={Images.EXC_VECTOR} className="ml-2" alt="" />
            )}
        </NavLink>
      ))}

      {!user.isMBAGrad && (
        <NavLink
          to={
            !isParent ? '/account/parent-accounts' : '/account/student-accounts'
          }
          className={navLink}
        >
          <img
            src={ParentAccountIcon}
            alt="parent"
            className={navLinkIcon}
          ></img>
          {!isParent ? 'Parent/Guardian Accounts' : 'Student Accounts'}
        </NavLink>
      )}
    </Box>
  );
};

export default AccountMenu;
