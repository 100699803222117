import gql from 'graphql-tag';

export const SAVE_USER_HIGHSCHOOL_DATA = gql`
  mutation saveUserHighSchoolData(
    $userId: Int!
    $highSchoolName: String
    $highSchoolYear: String
    $highSchoolState: String
    $undergraduateGradYear: String
    $undergraduateCollege: String
  ) {
    saveUserProfileData(
      input: {
        userId: $userId
        highSchoolName: $highSchoolName
        highSchoolYear: $highSchoolYear
        highSchoolState: $highSchoolState
        undergraduateGradYear: $undergraduateGradYear
        undergraduateCollege: $undergraduateCollege
      }
    ) {
      success
      message
    }
  }
`;

export const SAVE_GPA_DATA = gql`
  mutation saveUserGpaData(
    $userId: Int!
    $gpa: Float
    $gpaNonWeighted: Float
    $gpaWeighted: Float
    $sat: Int
    $act: Int
  ) {
    saveUserProfileData(
      input: {
        userId: $userId
        gpa: $gpa
        gpaNonWeighted: $gpaNonWeighted
        gpaWeighted: $gpaWeighted
        sat: $sat
        act: $act
      }
    ) {
      success
      message
    }
  }
`;

export const SAVE_AP_COURSES = gql`
  mutation saveUserApCourses($userId: Int!, $apCourses: [Int]) {
    saveUserProfileData(input: { userId: $userId, apCourses: $apCourses }) {
      success
      message
    }
  }
`;
export const SAVE_LOCATION = gql`
  mutation saveUserLocation($userId: Int!, $location: [String]) {
    saveUserProfileData(input: { userId: $userId, location: $location }) {
      success
      message
    }
  }
`;
export const UPDATE_SMALL_SCHOOL = gql`
  mutation updateSmallSchool($userId: Int!, $smallSchool: Boolean) {
    saveUserProfileData(input: { userId: $userId, smallSchool: $smallSchool }) {
      success
      message
    }
  }
`;
export const UPDATE_MEDIUM_SCHOOL = gql`
  mutation updateMediumSchool($userId: Int!, $mediumSchool: Boolean) {
    saveUserProfileData(input: { userId: $userId, mediumSchool: $mediumSchool }) {
      success
      message
    }
  }
`;
export const UPDATE_LARGE_SCHOOL = gql`
  mutation updateLargeSchool($userId: Int!, $largeSchool: Boolean) {
    saveUserProfileData(input: { userId: $userId, largeSchool: $largeSchool }) {
      success
      message
    }
  }
`;
export const UPDATE_MASSIVE_SCHOOL = gql`
  mutation updateExtraLargeSchool($userId: Int!, $extraLargeSchool: Boolean) {
    saveUserProfileData(input: { userId: $userId, extraLargeSchool: $extraLargeSchool }) {
      success
      message
    }
  }
`;
export const UPDATE_PRIVATE_COLLEGE = gql`
  mutation updatePrivateCollege($userId: Int!, $privateCollege: Boolean) {
    saveUserProfileData(input: { userId: $userId, privateCollege: $privateCollege }) {
      success
      message
    }
  }
`;
export const UPDATE_PUBLIC_COLLEGE = gql`
  mutation updatePublicCollege($userId: Int!, $publicCollege: Boolean) {
    saveUserProfileData(input: { userId: $userId, publicCollege: $publicCollege }) {
      success
      message
    }
  }
`;

export const SAVE_IB_COURSES = gql`
  mutation saveUserIbCourses($userId: Int!, $IbCourses: [Int]) {
    saveUserProfileData(input: { userId: $userId, IbCourses: $IbCourses }) {
      success
      message
    }
  }
`;

export const SAVE_USER_INTERESTS = gql`
  mutation saveUserInterests($userId: Int!, $areasOfInterest: [Int]) {
    saveUserProfileData(
      input: { userId: $userId, areasOfInterest: $areasOfInterest }
    ) {
      success
      message
    }
  }
`;

export const ADD_STUDENT_ACTIVITY = gql`
  mutation addStudentExtraCurricularActivity(
    $id: Int
    $userId: Int!
    $title: String
    $details: String
    $ExtracurricularCategoryId: Int
    $startedAt: Date
    $endedAt: Date
    $currentlyActive: Boolean
    $leadershipExperience: Boolean
    $role: String
    $areaOfInterests: [Int]
  ) {
    addStudentExtraCurricularActivity(
      input: {
        id: $id
        userId: $userId
        title: $title
        details: $details
        ExtracurricularCategoryId: $ExtracurricularCategoryId
        startedAt: $startedAt
        endedAt: $endedAt
        currentlyActive: $currentlyActive
        leadershipExperience: $leadershipExperience
        role: $role
        areaOfInterests: $areaOfInterests
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_STUDENT_ACTIVITY = gql`
  mutation deleteStudentExtraCurricularActivity($id: Int!, $userId: Int!) {
    deleteStudentExtraCurricularActivity(id: $id, userId: $userId) {
      success
      message
    }
  }
`;
