import { FC, ChangeEvent, useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Field, ErrorMessage } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  getApCourses,
  getIbCourses,
  getExtraCurricularCategories,
  getAllLanguages,
} from './Questions/static';
import {
  Box,
  Typography,
  Tooltip,
  Collapse,
  Grid,
  Divider,
  Checkbox,
} from '@material-ui/core';

import { TextField } from '../../../components/fields';
import { zones } from '../../../components/TimeZonePicker';
import { questionsInterface, selectOption } from './IntakeFormStepper';
import AddNewActivityAwardDialog from '../account/AddNewActivityAwardDialog';

import {
  checkIfInnerQuestionIsRequired,
  loadOptionsforSpecificQuery,
} from './Questions/static';
import {
  convertTimeZoneForFE,
  convertTimeZoneForDB,
} from '../../../common/utility';
import { getUserFromIntakeTokenQuery_getUserFromIntakeToken } from './gql/__generated__/getUserFromIntakeTokenQuery';

import DeleteActivityDialog from '../account/DeleteActivityDialog';

import { useStyles } from './style';

import Colors from '../../../common/colors';

import { US_STATES_NAMES, COUNTRY_NAMES } from '../../../common/constants';
import moment from 'moment';
import { getStudentActivitiesData } from '../account/graphql/queries/myProfile/__generated__/getStudentActivitiesData';
import { GET_STUDENT_ACTIVITIES } from '../account/graphql/queries/myProfile';
import Loader from '../../../components/Loader';
import { Query } from 'react-apollo';

interface Props {
  placeholder?: string;
  setFieldValue: Function;
  questionNumber: number;
  values: any;
  questionData: questionsInterface;
  user: getUserFromIntakeTokenQuery_getUserFromIntakeToken;
}
interface zonesInterface {
  label: string;
  value: string;
}

const IntakeFormQuestion: FC<Props> = ({
  placeholder,
  setFieldValue,
  values,
  questionNumber,
  questionData,
  user,
}) => {
  const classes = useStyles();
  const [radioValue, setRadioValue] = useState('');
  const [coursesAP, setCoursesAP] = useState([]);
  const [coursesIB, setCoursesIB] = useState([]);
  const [languages, setLanguages] = useState<zonesInterface[]>([]);
  const [studentActivities, setStudentActivities] = useState<any>([]);

  const {
    question,
    extraNote,
    type: fieldType,
    innerQuestion: innerQuestions,
    options: answersArray,
    query,
    className = '',
    classNameStyle = '',
    classNameCaption = '',
  } = questionData;
  const title = question.replaceAll('.', '');
  const zonesArray: zonesInterface[] = [];
  if (query === 'timezone') {
    zones.forEach((val) => {
      zonesArray.push({ label: val, value: val });
    });
  }

  const handleRadioChange = (
    title: string,
    { currentTarget }: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = currentTarget;

    !!innerQuestions.length &&
      innerQuestions[0].type !== 'phone' &&
      value !== 'Yes' &&
      value !== 'other' &&
      values[innerQuestions[0].question] &&
      setFieldValue(innerQuestions[0].question, undefined);

    innerQuestions.length &&
      innerQuestions[0].type === 'phone' &&
      value === 'Yes' &&
      setFieldValue(innerQuestions[0].question, user?.phoneNumber || '');

    setRadioValue(value);
    setFieldValue(title, value);
  };

  const handleLocationFields = (value: string) => {
    if (value === 'United States of America') {
      document
        .getElementsByName('Zip Code')[0]
        .parentElement?.parentElement?.parentElement?.removeAttribute('hidden');
      document
        .getElementsByName('State')[0]
        .parentElement?.parentElement?.removeAttribute('hidden');
    } else {
      document
        .getElementsByName('Zip Code')[0]
        .parentElement?.parentElement?.parentElement?.setAttribute(
          'hidden',
          ''
        );
      document
        .getElementsByName('State')[0]
        .parentElement?.parentElement?.setAttribute('hidden', '');
    }
  };

  const handleSelect = (selected: selectOption[]) => {
    if (
      title.includes('Language Preference') &&
      innerQuestions.length &&
      !!innerQuestions[0]?.question
    ) {
      setFieldValue(innerQuestions[0].question, selected);
    } else {
      const isOtherSelected = selected.find((item) => item.value === 'other');
      innerQuestions.length &&
        !isOtherSelected &&
        setFieldValue(innerQuestions[0].question, undefined);
      setFieldValue(title, selected);
      if (title.includes('Where are you located')) {
        handleLocationFields(selected[0].value || '');
        setFieldValue('Zip Code', '');
        setFieldValue('State', '');
      }
    }
  };

  let numberProps: any = { fullWidth: true };
  if (fieldType === 'number') {
    numberProps = { ...numberProps, inputProps: { step: 'any' } };
  }

  const stateCodes = Object.keys(US_STATES_NAMES);
  stateCodes.sort();
  const statesList = stateCodes.map((state) => {
    return { label: state, value: state };
  });

  const countriesList = COUNTRY_NAMES.map((country: string) => {
    COUNTRY_NAMES.sort();
    return { label: country, value: country };
  });

  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  const temporaryArray = [1, 2, 3, 4, 5, 6, 7];
  const YEARS_ARRAY: any = [{ label: currentYear, value: currentYear }];
  temporaryArray.forEach((val) => {
    YEARS_ARRAY.push({ label: val + currentYear, value: val + currentYear });
  });

  const getcoursesAP = async () => {
    let courses = await getApCourses();
    courses = courses.map((course: any) => {
      return { value: course.id, label: course.courseName };
    });
    setCoursesAP(courses);
  };
  const getcoursesIb = async () => {
    let courses = await getIbCourses();
    courses = courses.map((course: any) => {
      return { value: course.id, label: course.courseName };
    });
    setCoursesIB(courses);
  };

  const getLanguages = async () => {
    let languages = await getAllLanguages();
    languages = languages.map((language: { id: number; name: string }) => {
      return { value: language.id, label: language.name };
    });
    setLanguages(languages);
  };

  const showStudentActivities = async () => {
    return new Promise((resolve, reject) => {
      const activities = () => {
        if (document.getElementsByClassName('extracurricularsScrollGrid')[0])
          document
            .getElementsByClassName('extracurricularsScrollGrid')[0]
            .parentElement?.removeAttribute('hidden');
      };

      resolve(
        setTimeout(() => {
          activities();
        }, 1000)
      );
    });
  };

  useEffect(() => {
    localStorage.removeItem('isOptionDisabled');
    getcoursesAP();
    getcoursesIb();
    getLanguages();

    if (
      document.getElementsByName('State')[0] ||
      document.getElementsByName('Zip Code')[0]
    )
      handleLocationFields(values['Where are you located?']?.[0]?.value || '');
  }, []);

  useEffect(() => {
    showStudentActivities();
  });

  return (
    <Box pb={3.5} className={classNameStyle ? 'containerColumnBox' : ''}>
      <Box
        color={Colors.BLACK_TEN}
        pb={query === 'highschool' ? 0 : 1}
        className={classNameStyle ? 'paddingBottom' : ''}
      >
        <Typography
          variant="body1"
          color="inherit"
          className={classNameStyle ? 'fieldCaptionText' : ''}
        >
          <strong>
            {question}
            {questionData.required && ` *`}
          </strong>
          {question === 'Is this your cell phone number?' &&
            ` (${user?.phoneNumber || '--'})`}
          {extraNote}
        </Typography>
      </Box>
      {fieldType === 'textarea' && (
        <Field
          className={`${classes.textArea} textareaScroll`}
          name={title || ''}
          as="textarea"
          value={values[title] || ''}
        ></Field>
      )}
      {(fieldType === 'text' ||
        fieldType === 'email' ||
        fieldType === 'number') && (
        <>
          {classNameCaption === 'timeZoneInput' ? (
            <Typography variant="body1" className="fieldCaptionText">
              Time Zone
            </Typography>
          ) : (
            ''
          )}

          <Field
            type={
              fieldType === 'email'
                ? 'email'
                : fieldType === 'number'
                ? 'number'
                : 'text'
            }
            variant="outlined"
            {...numberProps}
            name={title || ''}
            component={TextField}
            placeholder={placeholder || ''}
            label={false}
            value={values[title] || ''}
            onChange={(e: any) => {
              setFieldValue(title, e.target.value);
            }}
            classNameStyle={className}
            className={
              classNameCaption === 'timeZoneInput' ? 'timeZoneWidth' : ''
            }
          />
        </>
      )}
      {fieldType === 'radio' && answersArray && (
        <Box display="flex" margin="0 -6px">
          {answersArray.map((item, index) => (
            <Tooltip
              title={item}
              arrow
              key={`intake-answer-${index}`}
              placement="top"
            >
              <Box
                className="radio-container"
                maxWidth="25%"
                flex={1}
                px={0.75}
              >
                <input
                  type="radio"
                  name={title || ''}
                  defaultChecked={values && item === values[title]}
                  onChange={(e) => handleRadioChange(title, e)}
                  id={`${item}-${index}-${questionNumber}`}
                  value={item}
                />
                <label htmlFor={`${item}-${index}-${questionNumber}`}>
                  <span>{item}</span>
                </label>
              </Box>
            </Tooltip>
          ))}
        </Box>
      )}
      {question.includes('personal pronouns?') && (
        <Box
          display="flex"
          alignItems="center"
          className={classes.preferAdvisor}
        >
          <Checkbox
            defaultChecked={values.isPreferredPronouns ? true : false}
            onChange={() => {
              setFieldValue('isPreferredPronouns', !values.isPreferredPronouns);
            }}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
            color="default"
          />
          <Typography variant="body1">
            I prefer having an Advisor with the same pronouns. (We will do our
            best to accommodate)
          </Typography>
        </Box>
      )}
      {fieldType === 'button' && (
        <>
          <Query<getStudentActivitiesData>
            query={GET_STUDENT_ACTIVITIES}
            fetchPolicy="network-only"
            variables={{
              userId: user?.id,
            }}
          >
            {({ data, loading, refetch }) => {
              if (loading) return <Loader />;

              setStudentActivities(
                data?.getProfileData?.studentExtraCurricularActivities
              );
              const areasOfInterest = data?.getAreasOfInterest
                ? data?.getAreasOfInterest
                    .map((interest) => {
                      return {
                        label: interest?.shortName,
                        value: interest?.id,
                        displayName: interest?.displayName,
                      };
                    })
                    .sort((a: any, b: any) => {
                      return a?.label
                        ?.toLowerCase()
                        .localeCompare(b?.label?.toLowerCase());
                    })
                : [];
              const categories = data?.getExtraCurricularCategories;

              return (
                <Grid
                  className={
                    studentActivities && studentActivities?.length > 0
                      ? 'extracurricularsScrollGrid scrollGridHeight'
                      : 'extracurricularsScrollGrid'
                  }
                >
                  {studentActivities &&
                    studentActivities?.length > 0 &&
                    studentActivities.map((activity: any, index: number) => {
                      return (
                        <>
                          <Grid>
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="space-between"
                            >
                              <Box>
                                {activity?.title && (
                                  <Typography variant="h5">
                                    {activity?.title ? activity.title : '--'}
                                  </Typography>
                                )}

                                <Typography variant="h6">
                                  Category:{' '}
                                  {activity?.extraCurricularActivity
                                    ?.category || '--'}
                                </Typography>
                                <Typography variant="h6">
                                  {`${
                                    activity?.startedAt
                                      ? moment(activity?.startedAt).format(
                                          'MM/YYYY'
                                        )
                                      : '--'
                                  } - ${
                                    activity?.endedAt
                                      ? moment(activity?.endedAt).format(
                                          'MM/YYYY'
                                        )
                                      : activity?.currentlyActive &&
                                        activity?.startedAt
                                      ? 'Present'
                                      : '--'
                                  } `}{' '}
                                </Typography>
                              </Box>

                              <Box display="flex" alignItems="center">
                                <AddNewActivityAwardDialog
                                  edit={true}
                                  activity={activity}
                                  categories={categories}
                                  userId={user?.id || undefined}
                                  refetch={refetch}
                                  profileRefetch={() => {}}
                                  key={index}
                                  areasOfInterest={areasOfInterest}
                                />

                                <DeleteActivityDialog
                                  userId={user?.id || undefined}
                                  refetch={refetch}
                                  activityId={activity?.id}
                                />
                              </Box>
                            </Box>
                            {activity?.leadershipExperience &&
                              activity?.role && (
                                <Typography variant="h6">
                                  {`Leadership (${activity?.role})`}
                                </Typography>
                              )}
                            <Typography variant="body1">
                              {activity?.details ? activity.details : '--'}
                            </Typography>
                          </Grid>
                          <Divider className="activitiesDivider" />
                        </>
                      );
                    })}

                  <AddNewActivityAwardDialog
                    edit={false}
                    categories={categories}
                    userId={user?.id || undefined}
                    refetch={refetch}
                    profileRefetch={() => {}}
                    areasOfInterest={areasOfInterest}
                  />
                </Grid>
              );
            }}
          </Query>
        </>
      )}
      {(fieldType === 'simple-select' ||
        fieldType === 'simple-multi-select') && (
        <>
          {className ? (
            <Typography variant="body1" className="fieldCaptionText">
              Search classes
            </Typography>
          ) : (
            ''
          )}
          <Select
            name={title || ''}
            isMulti={fieldType === 'simple-multi-select' ? true : false}
            value={
              title.includes('What time zone')
                ? [
                    {
                      label:
                        convertTimeZoneForFE(values[title]?.[0]?.label) || '',
                      value:
                        convertTimeZoneForFE(values[title]?.[0]?.value) || '',
                    },
                  ]
                : !values[title] || values[title]?.[0].length === 0
                ? []
                : values[title]
            }
            options={
              query === 'timezone'
                ? zonesArray
                : title.includes('State')
                ? statesList
                : title.includes('Where are you located')
                ? countriesList
                : title.includes('Grad Year')
                ? YEARS_ARRAY
                : title.includes('Please list any AP classes')
                ? coursesAP
                : title.includes('Please list any IB classes')
                ? coursesIB
                : answersArray?.map((item) => ({
                    value: item,
                    label: item,
                  }))
            }
            onChange={(e) => {
              console.log(title, e);
              if (
                title.includes(
                  'Top 3 priorities as you begin your CollegeAdvisor'
                ) &&
                e.length >= 3
              ) {
                localStorage.setItem('isOptionDisabled', 'true');
              } else {
                localStorage.setItem('isOptionDisabled', 'false');
              }
              if (e.length) handleSelect(e);
              else {
                if (query === 'timezone') {
                  handleSelect([
                    {
                      label: convertTimeZoneForDB(e.label) || '',
                      value: convertTimeZoneForDB(e.value) || '',
                    },
                  ]);
                } else handleSelect([e]);
              }
            }}
            className={className}
            classNamePrefix="select"
            placeholder={
              classNameStyle === 'gradYearPlaceholder' ? 'Select Grad Year' : ''
            }
            isOptionDisabled={() =>
              localStorage.getItem('isOptionDisabled') === null &&
              title.includes(
                'Top 3 priorities as you begin your CollegeAdvisor'
              ) &&
              values[title]?.length >= 3
                ? true
                : title.includes(
                    'Top 3 priorities as you begin your CollegeAdvisor'
                  )
                ? localStorage.getItem('isOptionDisabled') === 'true'
                : false
            }
          />
        </>
      )}
      {fieldType === 'phone' && (
        <Field
          name={title}
          placeholder="Parent’s Phone Number"
          validate={(value: any | string) => {
            if (value && !isValidPhoneNumber(value)) {
              return 'Invalid Phone Number';
            }
          }}
        >
          {({
            field,
            form: { errors, handleBlur, touched, setFieldTouched, ...rest },
            ...otherProps
          }: any) => {
            return (
              <Box className="phoneInputMasterResolve">
                <Box
                  className={
                    touched.parentPhone && errors.parentPhone ? 'error' : ''
                  }
                >
                  <PhoneInput
                    placeholder="Enter Phone Number"
                    value={values[title]}
                    defaultCountry="US"
                    {...rest}
                    {...otherProps}
                    onBlur={() => {
                      setFieldTouched(title, true);
                    }}
                    onChange={(value) => {
                      setFieldValue(title, value);
                    }}
                    error={touched[title] && errors[title]}
                  />
                </Box>
              </Box>
            );
          }}
        </Field>
      )}
      {(fieldType === 'async-multi-select' || fieldType === 'async-select') && (
        <>
          {query === 'college' ? (
            <Typography variant="body1" className="multiSelectCaption">
              If so, please list them.
            </Typography>
          ) : (
            ''
          )}
          {query === 'college' ? (
            <Typography
              variant="body1"
              className="fieldCaptionText fieldCaptionTextSpacing"
            >
              Select colleges
            </Typography>
          ) : (
            ''
          )}
          {query === 'highschool' ? (
            <Typography
              variant="body1"
              className="multiSelectCaption multiSelectCaptionSpacing"
            >
              Skip this question if you have not attended any other high
              schools.
            </Typography>
          ) : (
            ''
          )}

          <AsyncSelect
            isMulti={fieldType === 'async-multi-select' ? true : false}
            isClearable={true}
            cacheOptions
            loadOptions={(e) => loadOptionsforSpecificQuery(e, query)}
            defaultOptions
            value={values[title]}
            onChange={(e) => {
              if (!e) setFieldValue(title, []);
              else if (Array.isArray(e)) setFieldValue(title, e);
              else setFieldValue(title, [e]);
            }}
            placeholder={
              query === 'college' ? 'Select College' : 'Select High School'
            }
            classNamePrefix="select"
            maxMenuHeight={200}
            className={className}
          />
        </>
      )}
      <Collapse
        in={
          fieldType === 'async-select' ||
          title.includes('major are you interested in studying?')
            ? checkIfInnerQuestionIsRequired(title, values[title])
            : checkIfInnerQuestionIsRequired(
                title,
                radioValue || values[title]
              ) && !!innerQuestions.length
        }
        mountOnEnter
        unmountOnExit
      >
        {innerQuestions.map((item, index) =>
          item.type === 'text' ||
          item.type === 'email' ||
          item.type === 'number' ? (
            <Box pt={1.5} key={`inner-- ${index}`}>
              <Field
                key={index + 'inner-question'}
                type={item.type}
                variant="outlined"
                name={item.question}
                value={values[item.question] || ''}
                fullWidth={true}
                component={TextField}
                placeholder={item.question || ''}
                label={false}
                onChange={(e: any) => {
                  if (item.type === 'number' && e.target.value > 0)
                    setFieldValue(item.question, e.target.value);
                  else if (item.type !== 'number')
                    setFieldValue(item.question, e.target.value);
                }}
              />
            </Box>
          ) : item.type === 'simple-multi-select' ? (
            <Select
              name={''}
              isMulti={title.includes('Language Preference') ? true : false}
              value={values[innerQuestions[0]?.question]}
              options={languages}
              onChange={(e) => {
                handleSelect(e);
              }}
              className={className}
              classNamePrefix="select"
              placeholder={'Select Languages'}
            />
          ) : (
            item.type === 'phone' && (
              <Box pt={1.5} key={`inner-- ${index}`}>
                <Field
                  name={item.question}
                  placeholder="Parent’s Phone Number"
                  validate={(value: any | string) => {
                    if (value && !isValidPhoneNumber(value)) {
                      return 'Invalid Phone Number';
                    }
                  }}
                >
                  {({
                    field,
                    form: {
                      errors,
                      handleBlur,
                      touched,
                      setFieldTouched,
                      ...rest
                    },
                    ...otherProps
                  }: any) => {
                    return (
                      <Box className="phoneInputMasterResolve">
                        <Box
                          className={
                            touched.parentPhone && errors.parentPhone
                              ? 'error'
                              : ''
                          }
                        >
                          <PhoneInput
                            placeholder="Enter Phone Number"
                            value={values[item.question]}
                            defaultCountry="US"
                            {...rest}
                            {...otherProps}
                            onBlur={() => {
                              // setFieldTouched(item.question, true);
                            }}
                            onChange={(value) => {
                              setFieldValue(item.question, value);
                            }}
                            error={
                              touched[item.question] && errors[item.question]
                            }
                          />
                          {errors[item.question] && (
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                              {errors[item.question]}
                            </p>
                          )}
                        </Box>
                      </Box>
                    );
                  }}
                </Field>
              </Box>
            )
          )
        )}
      </Collapse>
      <Box className="errorMessage">
        <ErrorMessage name={title} />
      </Box>
    </Box>
  );
};

export default IntakeFormQuestion;
